<template>
  <div class="card p-2" style="min-height: 200px">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>CS No</th>
          <th>CS Date</th>
          <th>Requester</th>
          <th>Project</th>
          <th>Department</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in csList.data" :key="i">
          <td>{{ item.cs_number }}</td>
          <td>{{ item.cs_date }}</td>
          <td>{{ item.user.name }}</td>
          <td>{{ item.project.name }}</td>
          <td>{{ item.department.name }}</td>
          <td>{{ item.status }}</td>
          <td>
            <button class="btn btn-primary btn-sm" title="Edit CS"
                    @click="editCS(item.id, item.requisition_id)"
                    style="cursor: pointer"
            >
              <i class="fas fa-edit"></i>
            </button>
            <button class="btn btn-secondary btn-sm" title="Print CS"
                    @click="printCS(item.id, item.requisition_id)"
                    style="cursor: pointer; margin-left: 10px"
            >
              <i class="fas fa-print"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
      <p v-if="!csList.total" class="text-center mt-5">No data</p>    </div>
  </div>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";

const props  = defineProps({
  csList: Object,
});
const route  = useRoute();
const router = useRouter();

function editCS(id,requisition_id){
  let param = {
    companyId: route.params.companyId,
    moduleId: route.params.moduleId,
    menuId: route.params.menuId,
    pageId: route.params.pageId,
  }

  let query = route.query;
  query.requisitionId = requisition_id;
  query.csId = id;

  router.push({
    name: 'cs-add',
    params: param,
    query: query
  })
}

function printCS(id,requisition_id){
  let param = {
    companyId: route.params.companyId,
    moduleId: route.params.moduleId,
    menuId: route.params.menuId,
    pageId: route.params.pageId,
  }

  let query = route.query;
  query.requisitionId = requisition_id;
  query.csId = id;

  router.push({
    name: 'cs-print',
    params: param,
    query: query
  })
}
</script>
