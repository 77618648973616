<template>
  <div style="min-height: calc(100vh - 158px);" class="card">
    <div style="background: #DBE7F2" class="p-2">
      <TitleButton
          btnTitle="Add New"
          :showBtn="false"
          :showSettingBtn="false"
          :showAddNew="false"
          title="CS List"
      />
      <DateQuerySetter @onClickGo="getCSList">
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Select Project</label>
          <v-select
            placeholder="Select Project"
            :options="projects"
            label="name"
            :reduce="name => name.id"
            v-model="projectId"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Department</label>
          <v-select
            placeholder="Select Department"
            v-model="departmentId"
            :options="departments"
            label="name"
            :reduce="name => name.id"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label class="col-form-label">Supplier</label>
          <vField v-model="supplier_id" class="d-none" name="supplier_id"/>
          <v-select
            placeholder="Select Supplier"
            v-model="supplier_id"
            :options="contacts"
            label="name"
            :reduce="name => name.id"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Requisition By</label>
          <v-select
            placeholder="Select Requisition By"
            v-model="requisitionUserId"
            :options="requisitionUsers"
            label="name"
            :reduce="name => name.id"
          />
        </div>
        <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
          <label class="col-form-label" for="cs-by">CS No</label>
          <input id="cs-by" type="text" class="form-control" v-model="csNumber">
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Status</label>
          <v-select
            placeholder="Select Status"
            v-model="status"
            :options="approvalStatus"
            label="name"
            :reduce="name => name.id"
          />
        </div>
      </DateQuerySetter>
    </div>

    <ListTable :cs-list="csList"/>
    <div class="mb-2"></div>
    <div class="px-2 position-absolute bottom-0">
      <Pagination
          :offset="offset"
          @onPageChange="onPageChange"
          ref="pagination"
      />
    </div>
  </div>

  <GlobalLoader/>
</template>

<script setup>
import {computed, ref, onMounted} from 'vue';
import TitleButton             from '@/components/atom/TitleButton'
import DateQuerySetter         from '@/components/atom/DateQuerySetter'
import ListTable               from '@/components/molecule/procurement/vendor-selection/comparative-statement/CSListingTable.vue'
import Pagination              from '@/components/atom/Pagination'
import { useRouter, useRoute }    from 'vue-router'
import handleRequisitions from "@/services/modules/procurement/requisition";
import handleProjects from "@/services/modules/procurement/project";
import handleDepartments from "@/services/modules/procurement/organization-settings/department";
import handleInventory from "@/services/modules/inventory";
import handleContact from "@/services/modules/contact";
import {useStore} from "vuex";

const router = useRouter()
const route = useRoute()
const store = useStore()
const {fetchCSList} = handleRequisitions();
const {fetchProjects} = handleProjects();
const {fetchDepartmentList} = handleDepartments();
const {fetchHome} = handleInventory();
const {fetchContactProfiles} = handleContact()
const {getRequisitionUserList} = handleRequisitions();
const offset = ref(10);
let page = ref(1);
let loading = ref(false);
let projectId = ref(null);
const departmentId = ref(null);
const requisitionUserId = ref(null);
let supplier_id = ref(null)
let status = ref(null)
let csList = ref({});
const departments = ref([]);
const projects = ref([]);
const csNumber = ref('');
const requisitionUsers = ref([]);
const homeContent = ref([]);
let contacts = ref([])
let companyId = computed(() => route.params.companyId);

function navigateToAddPage() {
  router.push({name: 'cs-add', params: route.params, query: route.query})
}

function onClickSettingButton() {
  alert('Setting Button clicked');
}


async function getCSList() {
  try {
    csList.value = [];
    const res = await fetchCSList(getQuery());
    if (res.status) {
      csList.value = res.data;
      setPaginationData({
        records: res.data.total,
        perPage: res.data.per_page,
        currentPage: res.data.current_page,
      })
    }
  } catch (err) {
    if (err.response) {
      showError(err.response.message)
    }
  }
}

const setPaginationData = (data) => {
  store.commit('setPaginationData', data)
};

const approvalStatus = computed(() => {
  let approvalStatus = [];
  if(homeContent.value.hasOwnProperty('approval_status')) {
    Object.keys(homeContent.value.approval_status).forEach( id => {
      approvalStatus.push({
        id: id,
        name: homeContent.value.approval_status[id],
      })
    })
  }
  return approvalStatus;
})

function getQuery() {
  let query = '?company_id=' + companyId.value
  query += '&offset=' + offset.value
  query += '&start_date=' + route.query.start
  query += '&end_date=' + route.query.end
  query += '&without_user_check=1'
  if(projectId.value) {
    query += `&project_id=${projectId.value}`
  }
  if(departmentId.value) {
    query += `&department_id=${departmentId.value}`
  }
  if(supplier_id.value) {
    query += `&supplier_id=${supplier_id.value}`
  }
  if(requisitionUserId.value) {
    query += `&requisition_user_id=${requisitionUserId.value}`
  }
  if(csNumber.value) {
    query += `&cs_number=${csNumber.value}`
  }
  if(status.value) {
    query += `&status=${status.value}`
  }
  query += '&page=' + (route.query.page ?? page.value)
  return query
}

function onPageChange(page) {
  let routeQuery = Object.assign({}, route.query)
  routeQuery.page = page
  router.push({path: route.path, query: routeQuery})
  setTimeout(() => {
    getCSList();
  }, 100)
}

onMounted(async () => {
  loading.value = true;
  let query = '?company_id=' + companyId.value

  Promise.all([
    getCSList(),
    fetchProjects(query).then( res => {
      if(res.status) {
        projects.value = res.data;
      }
    }),
    fetchHome().then( res => {
      if(res.status) {
        homeContent.value = res.data;
      }
    }),
    fetchDepartmentList(query).then( res => {
      if(res.status) {
        departments.value = res.data;
      }
    }),
    getRequisitionUserList(query).then( res => {
      if(res.status) {
        requisitionUsers.value = res.data;
      }
    }),
    fetchContactProfiles(query).then(res => {
      if (res.data) contacts.value = res.data
    }),
  ])
    .then ( () => loading.value = false)
    .catch( (err) => {
      loading.value = false
      showError( err.response.message)
    })
    .finally( () => loading.value = false)
})
</script>
